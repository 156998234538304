import logo from './img/media_web_cd39c8a.gif';
import {Button} from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <header className="App-header"  >
          <div className='box'>
            <span>
              <img src={logo} className="App-logo" alt="logo" />
            </span>
          </div>
          <div className='interacao'>
            <br/>
            <p>
              Em Breve a Intenligência Artificial vai revolucionar seu escrítorio.
            </p>
            <Button 
              variant="outline-info"
              onClick={(e) => {
                window.location.href = "mailto:diretoria.iaintegrador@gmail.com";
                e.preventDefault();
            }}
            >
              Solicite um contato
            </Button>
          </div>
      </header>
    </div>
  );
}

export default App;
